

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
