
html body {
  overflow-x: hidden;
}

.one {
  color: rgb(0, 166, 0);
}
.head-ul .he:hover {
  color: rgb(0, 166, 0);
}
/* ======================= header styling ========================*/
.servicesmenu:hover .servicedropdownitem {
  display: block;
  transition: all 50s ease-in-out;
}
.servicedropdownitem {
  display: none;
  transition: all 50s ease-in-out;
}
.footer-banner {
  background-image: url("./assets/images/home-images/footer-banner.jpg");
  height: 500px;
  width: 100%;
  background-position: bottom;
}
@media (max-width: 1024.98px) {
  .footer-banner {
    height: 800px;
  }
}
@media (max-width: 647.98px) {
  .footer-banner {
    height: 900px;
  }
}
@media (max-width: 450.98px) {
  .footer-banner {
    height: 1000px;
  }
}
@media (max-width: 400.98px) {
  .footer-banner {
    height: 1100px;
  }
}
.header-line {
  background: linear-gradient(to right, #1cd400 0%, #363636 100%);
  background-repeat: no-repeat;
  background-position: left 100%;
  transition-duration: 1s;
  background-size: 0 6%;
}
.header-line:hover {
  background-size: 100% 6%;
}
/* ==================================== Home banner Image ======================== */
.banner-image {
  background-image: url("./assets//images/home-images/banner-image.jpg");
  background-attachment: fixed;
  height: 600px;
  width: 100%;
  background-size: cover;
}

.styled legend {
  color: rgb(255, 187, 0);
  margin-left: 35%;
  font-size: 100px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Great Vibes", cursive;
}

.styled fieldset {
  border: 2px solid rgb(197, 196, 196);
  height: auto;
  width: 95%;
}
@media (max-width: 575.98px) {
  .styled legend {
    color: rgb(255, 187, 0);
    text-align: center;
    margin-left: 0;
    font-size: 100px;
    padding-left: 0px;
    padding-right: 0px;
    font-family: "Great Vibes", cursive;
  }
  .styled fieldset {
    border: none;
    width: 100%;
  }
}
/* ================================================================== */
.Slide-image-bg {
  background-image: url("./assets/images/home-images/corporate-events-catering.jpg");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
}
.Contact-banner {
  background-image: linear-gradient(
    150deg,
    #000000 0%,
    rgba(51, 51, 51, 0.1) 100%
  );
}
/* ============================================================ */
.AboutusBgImage {
  background-image: url("./assets/images/about-us-images/bgimageaboutus.png");
  height: 100%;
  width: 100%;
}
/* ============================================================ */
.tabs {
  font-family: "Cormorant Infant", serif;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  margin-bottom: 60px;
}

.tab-buttons {
  margin-bottom: 50px;
}

button {
  margin-right: 15px;
  font-size: 20px;
  border: none;
  outline: none;
  padding: 0.5% 2.5%;
  cursor: pointer;
  color: rgb(0, 0, 0);
  transition: all ease-in-out 0.2s;
  border-bottom: 2px solid transparent;
  font-weight: bolder;
  background-color: rgb(239, 238, 238);
  margin-bottom: 15px;
  border-radius: 8px;
  text-transform: uppercase;
}
.active {
  background: #38b200;
  border-radius: 8px;
  color: rgb(255, 255, 255);
}
/* =====================menu bg ============== */

.scroll {z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 40px;
  right: -80px;
  border-radius: 100%;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%, rgba(90, 92, 106, 1) 0%, rgba(32, 45, 58, 1) 81.3%
  );
  color: #fff;
  box-shadow: 0 0 5px 0px #888;
  transition: all 400ms;
}

.scroll.visible {
  right: 30px;
  transition: all 400ms;
  transform: rotate(360deg);
}

.scroll i {
  margin-top: 10px;
  text-shadow: 0 0 2px #fff;
}

.scroll:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  from {
    margin-top: 15px;
  }
  to {
    margin-top: 5px;
  }
}
/* ==================================== */
.email-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
/* =============================== */
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
  margin-left: 16px;
}